import "./App.css";
import logo from "./logo.svg";
import React ,{useEffect} from "react";
import Routes from "./routes";
import {PageView, initGA} from "../src/components/Tracking/index"
const App = () => {
  useEffect(()=>{
    initGA('UA-190094717-1');
    PageView();
  },[])
  const highScore = localStorage.getItem('highScore')
  const setHighScoreValue = highScore?highScore:0
  localStorage.setItem('highScore',setHighScoreValue);
  const highScoreAliWonder = localStorage.getItem('highScoreALiWonder')
  const setHighScoreValueAliWonder = highScoreAliWonder?highScoreAliWonder:0
  localStorage.setItem('highScoreALiWonder',setHighScoreValueAliWonder);
  const skyHighScore = localStorage.getItem('skyHighScore')
  const setSkyHighScoreValue = skyHighScore?skyHighScore:0
  localStorage.setItem('skyHighScore',setSkyHighScoreValue);
  return (
  <div className="">
    <Routes />
  </div>
)};

export default App;
